import Image from "next/image"
import { Gradient } from "../Helpers/Gradient"
import Tourist from "../../public/turista.png"

export const Microsites = () => {
  return (
    <>
      <section className="pt-2">
        <div className="container">
          <Gradient type={"Small"} />
          <div className="pt-12 md:pb-12 grid grid-cols-1 md:grid-cols-2 items-center">
            <div>
              <Image src={Tourist} layout='responsive' alt="QR | QRDgo"/>
            </div>
            <div className="text-center md:text-left mt-6 md:mt-0 pb-12 md:pb-0">
              <h3 className="text-purple-200 text-5xl mb-4">Vive una <b>Experiencia Turística </b> excepcional</h3>
              <p className="text-gray-200 text-xl font-normal leading-8 mb-4">Encuentra una gran cantidad de información turística en el <b>Estado de Durango</b>, lugares increibles por visitar y emocionantes momentos que siempre recordarás.</p>
              <button className="bg-purple-400 text-white text-sm rounded-2xl py-2 px-8 font-bold">Ver Micrositios Turísticos</button>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
