import type { NextPage } from 'next'
import { Directory } from '../components/Home/Directory'
import { Microsites } from '../components/Home/Microsites'
import { SeeMore } from '../components/Home/SeeMore'
import { Slider } from '../components/Home/Slider'
import { Layout } from '../layouts/MainLayout'

const Home:NextPage = () => {
  
  return (
    <Layout inhome={true}>
      <Slider />
      <Directory />
      <Microsites />
      <SeeMore />
    </Layout>
  )
}

export default Home
