import { CategoryList } from "../../utils/categorys"
import Image from "next/image"
import Logo from "../../public/QRDGO_icon_pink_purple.svg"
import Link from "next/link"
// import { useAnimation } from "framer-motion"
// import { useInView } from "react-intersection-observer";
// import { useEffect } from "react"
import SVG from "react-inlinesvg";

export const Directory = () => {
  // const controls = useAnimation();
  // const [ref, inView] = useInView({
  //   threshold: 1
  // });
  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible");
  //   }else {
  //     controls.start("hidden");
  //   }
  // }, [controls, inView]);
  return (
    <section className="py-8 md:py-16 bg-gradient-to-b from-purple via-white to-transparent">
      <div className="container">
        <div className="md:flex text-center justify-center items-center mb-12 px-4">
          <div className="md:w-20 w-1/4 mx-auto md:mx-0 mb-2 md:mb-0">
            <Image src={Logo} layout="responsive" alt="QRDgo" className=" md:mb-0"/>
          </div>
          <div className="md:border-l-2 border-purple-200 md:pl-4 md:ml-4">
            <h2 className="text-purple-200 text-2xl md:text-4xl">Una <b>Oferta Turistica</b> exepcional</h2>
            <p className="text-purple-200 text-md mb-2">Conoce lugares <b>increibles</b> por visitar y vive <b>momentos</b> que siempre recordarás.</p>
          </div>
        </div>
        <div className="grid grid-cols-3 px-4 md:px-0 md:grid-cols-6 gap-y-6 gap-x-4 md:gap-12 mb-12">
          {CategoryList.map((category, index) => (
            // <motion.div ref={ref} initial="hidden" animate={controls} key={category.title}  
            //       variants={{
            //         visible: { opacity: 1, transition: { 
            //           delay: index*.1,
            //         } },
            //         hidden: { opacity: 0 }
            //       }} 
            //     >
                <Link  href={`/categoria/${category.title}`} key={category.title}>
                  <a >
                    <div className="text-center hover:cursor-pointer ">
                      <div className={`cube-before transition-all duration-500 ${category.type}`}>                         
                        <div className="cube transition-al duration-500">
                          <SVG src={category.img} className="transition-all duration-500"/>
                        </div>                  
                      </div>
                      <p className="text-purple-200 text-sm font-bold md:w-1/2 mx-auto text-center -mt-2"> {category.title}</p>
                    </div>
                  </a>
                </Link>
              // </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
