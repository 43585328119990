import Image from "next/image"
import Link from "next/link";

import Folleteria from "../../public/related/folleteria.png";
import Micrositios from "../../public/related/micrositios.png";
import Tarjetas from "../../public/related/tarjetas.png";

export const SeeMore = () => {
  return (
    <section className="bg-purple-300 py-12 px-12 md:px-0">
      <h3 className="text-center font-normal text-4xl block w-full mb-12 text-white">Revisa también...</h3>
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <Link href={"/categoria"}>
            <a>
              <div className="mb-4">
                <Image src={Tarjetas} layout='responsive' alt="Tarjetas Digitales | QRDgo"/>
              </div>
              <p className="text-white font-bold text-center m-0">Tarjetas Digitales</p>
            </a>
          </Link>
        </div>
        <div>
          <a href="http://folleteria.qrdgo.mx/" target="_blank" rel="noreferrer">
            <div className="mb-4">
              <Image src={Folleteria} layout='responsive' alt="Folletería Digital | QRDgo"/>
            </div>
            <p className="text-white font-bold text-center m-0">Folletería Digital</p>
          </a>
        </div>
        <div>
          <div className="mb-4">
            <Image src={Micrositios} layout='responsive' alt="Micrositios Turísticos | QRDgo"/>
          </div>
          <p className="text-white font-bold text-center m-0"> Micrositios Turísticos</p>
        </div>        
      </div>
    </section>
  )
}
