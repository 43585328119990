export const CategoryList = [  
  { type: "type1", img: "/icons/viajes.svg", title: "Agencias de Viajes" },  
  { type: "type2", img: "/icons/aviturismo.svg", title: "Aviturismo" },  
  { type: "type3", img: "/icons/balnearios.svg", title: "Balnearios" },
  { type: "type4", img: "/icons/bares.svg", title: "Bares" },
  { type: "type1", img: "/icons/cabanas.svg", title: "Cabañas" },
  { type: "type2", img: "/icons/cafeterias.svg", title: "Cafeterías" },
  { type: "type3", img: "/icons/cluster.svg", title: "CLUSTUR" },
  { type: "type4", img: "/icons/conectividad.svg", title: "Conectividad" },
  { type: "type1", img: "/icons/convenciones.svg", title: "Convenciones" },  
  { type: "type2", img: "/icons/eventos.svg", title: "Eventos" },
  { type: "type3", img: "/icons/hoteles.svg", title: "Hoteles" },
  { type: "type4", img: "/icons/instituciones.svg", title: "Instituciones" },
  { type: "type1", img: "/icons/mezcal.svg", title: "Mezcal" },
  { type: "type2", img: "/icons/municipios.svg", title: "Municipios Turísticos" },
  { type: "type3", img: "/icons/museos.svg", title: "Museos" },
  { type: "type4", img: "/icons/emergencias.svg", title: "Números de Emergencia" },
  { type: "type1", img: "/icons/paseos.svg", title: "Paseos Turísticos" },
  { type: "type2", img: "/icons/personal.svg", title: "Personales" },
  { type: "type3", img: "/icons/artesanales.svg", title: "Productos Típicos Artesanales" },
  { type: "type4", img: "/icons/magicos.svg", title: "Pueblos Mágicos" },
  { type: "type1", img: "/icons/restaurantes.svg", title: "Restaurantes" },
  { type: "type2", img: "/icons/operadores.svg", title: "Tour Operadores" },
  { type: "type3", img: "/icons/transportes.svg", title: "Transportes" },
  { type: "type4", img: "/icons/medico.svg", title: "Turismo Médico" },
];