import { Gradient } from "../Helpers/Gradient"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import Image from "next/image";

import slide1 from "../../public/slider/bg1xsld.jpg";
import slide2 from "../../public/slider/bg2xsld.jpg";
import slide3 from "../../public/slider/bg3xsld.jpg";

export const Slider = () => {
  return (
    <>
      <Carousel showThumbs={false} showStatus={false} autoPlay={true} interval={3000} emulateTouch={true} showIndicators={false}>
          <div>
              <Image src={slide1} layout="responsive" alt="asd" />                         
          </div>
          <div>
              <Image src={slide2} layout="responsive" alt="asd" />                         
          </div>
          <div>
              <Image src={slide3} layout="responsive" alt="asd" />                         
          </div>
      </Carousel>
      <Gradient type="Big" />
    </>
  )
}
