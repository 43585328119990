import { FC } from "react"

interface Props {
  type: String;
}

export const Gradient:FC<Props> = ({type}) => {
  return (
    <>
      {type == "Small" 
      ?
        <div className="w-full h-1 block" style={{backgroundImage:"linear-gradient(90deg, #94FCF2 0.9%, #6156C8 27.26%, #E577F7 52.84%, #6056C7 75.85%, #94FCF2 99.1%)"}}></div>
      :
        <div className="w-full h-2 block" style={{backgroundImage:"linear-gradient(90deg, #94FCF2 0.9%, #6156C8 27.26%, #E577F7 52.84%, #6056C7 75.85%, #94FCF2 99.1%)"}}></div>
      }
    </>
  )
}
